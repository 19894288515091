<template>
  <footer class="footer">
    <div class="footer-container">
      <div class="footer-block">
        <img class="footer-logo" src="../../../public/custom/images/footer-logo.svg" alt="Logo image">
        <div class="footer-links-container">
          <div class="first-block">
            <router-link class="footer-link" to="/about">{{ $t('message.footer.links.about') }}</router-link>
            <router-link class="footer-link" to="/contacts">{{ $t('message.footer.links.contacts') }}</router-link>
            <router-link class="footer-link" to="/news">{{ $t('message.footer.links.news') }}</router-link>
          </div>
          <div class="second-block">
            <router-link class="footer-link" to="/documents">{{ $t('message.footer.links.docs') }}</router-link>
            <router-link class="footer-link" to="/legislation">{{ $t('message.footer.links.legal') }}</router-link>
            <router-link class="footer-link" to="/arbitration">{{ $t('message.footer.links.justice') }}</router-link>
            <router-link class="footer-link" to="/questions">{{ $t('message.footer.links.questions') }}</router-link>
          </div>
          <div class="social-block">
            <div class="social-title">{{ $t('message.footer.social') }}</div>
            <div class="social-links">
              <a class="social-link" :href="link.href" v-for="link of socialsList" :key="link.iconName">
                <img class="social-link-img" :src="require(`../../../public/custom/images/social/${link.iconName}.svg`)" alt="Social icon">
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-underline"></div>
      <div class="legal-block">
        <!-- <div class="legal-item">{{ $t('message.footer.legal.cookies') }}</div> -->
        <div class="legal-item">{{ $t('message.footer.legal.company') }}</div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      socialsList: {
        instagram: {
          href: 'https://www.instagram.com/kapustaby?igsh=ZW1qMTU4aW1qdWNt',
          iconName: 'instagram'
        },
        tiktok: {
          href: 'https://www.tiktok.com/@kapustaby?_t=8jK3Puqw1eX&_r=1',
          iconName: 'tiktok'
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .footer {
    width: 100%;
    height: 360px;
    padding: 50px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--base-footer-bgc);
  }

  .footer-container {
    @include base-page-container;

    flex-direction: column;
  }

  .footer-block {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .footer-logo {
    width: 220px;
    height: 45px;
  }

  .footer-links-container {
    display: flex;
  }

  .first-block,
  .second-block {
    display: flex;
    flex-direction: column;
  }

  .second-block {
    margin-left: 160px;
    margin-bottom: 60px;
  }

  .footer-link,
  .social-title {
    padding-bottom: 20px;
    text-decoration: none;
    font-weight: 400;
    font-size: 18px;
    line-height: 135%;
    white-space: nowrap;
    color: var(--base-footer-links-color);
  }

  .social-block {
    margin-left: 160px;
    display: flex;
    flex-direction: column;
  }

  .social-links {
    display: flex;
    min-width: 242px;
  }

  .social-link {
    transition: all 0.2s ease;
  }

  .social-link:hover {
    transform: scale(1.1);
  }

  .social-link:not(:first-of-type) {
    margin-left: 10px;
  }

  .footer-underline {
    width: 100%;
    height: 2px;
    margin-bottom: 15px;
    border-radius: 3px;
    background-color: var(--base-footer-underline-bgc);
  }

  .legal-block {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .legal-item {
    width: calc(50% - 50px);
    color: var(--base-footer-legal-item-color);
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }

  @media screen and (max-width: 1750px) {
    .second-block,
    .social-block {
      margin-left: 120px;
    }
  }

  @media screen and (max-width: 1040px) {
    .footer {
      height: auto;
    }

    .footer-logo {
      width: 150px;
      height: 31px;
    }

    .footer-block {
      flex-direction: column;
    }

    .footer-logo {
      margin-bottom: 40px;
    }
    .footer-links-container {
      width: 100%;
    }

    .footer-links-container {
      flex-wrap: wrap;
    }

    .second-block,
    .social-block {
      margin-left: 5%;
    }
  }

  @media screen and (max-width: 585px) {
    .social-block {
      margin-left: 0;
      margin-bottom: 40px;
    }

    .legal-block {
      flex-direction: column;
    }

    .legal-item {
      width: 100%;
    }

    .legal-item:last-of-type {
      margin-top: 15px;
      text-align: start;
    }

    .first-block,
    .second-block {
      width: calc(50% - 20px);
    }
  }
</style>
